import React, { useEffect } from "react";
import { ParallaxProvider, ParallaxBanner } from "react-scroll-parallax";
import 'animate.css';

import bg0 from '../images/parallax/parallax_0.png'
import bg3 from '../images/parallax/parallax_3.png'
import bg4 from '../images/parallax/parallax_4.png'
import bg5 from '../images/parallax/parallax_5.png'
import bg6 from '../images/parallax/parallax_6.png'
import bg7 from '../images/parallax/parallax_7.png'
import bg8 from '../images/parallax/parallax_8.png'
import bg9 from '../images/parallax/parallax_9.png'
import bg10 from '../images/parallax/parallax_10.png'
import bg11 from '../images/parallax/parallax_11.png'


const Home = () => {

    useEffect(() => {
        localStorage.clear();
    }, [])

    return (
        <>
            <div id="nonparallax">
                <img alt="header mobile" src="../images/min/header-mobile.jpg" width="100%" />
            </div>
            <ParallaxProvider>
                <ParallaxBanner
                    className="banner"
                    layers={[

                        {
                            image: bg11,
                            translateY: [0, 0],
                            shouldAlwaysCompleteAnimation: true,
                            expanded: false,
                        },
                        {
                            image: bg10,
                            translateY: [0, 10],
                            shouldAlwaysCompleteAnimation: true,
                            expanded: false,
                        },
                        {
                            image: bg9,
                            translateY: [0, 20],
                            shouldAlwaysCompleteAnimation: true,
                            expanded: false,
                        },
                        {
                            image: bg8,
                            translateY: [5, 30],
                            shouldAlwaysCompleteAnimation: true,
                            expanded: false,
                        },
                        {
                            image: bg7,
                            translateY: [10, 40],
                            shouldAlwaysCompleteAnimation: true,
                            expanded: false,
                        },
                        {
                            image: bg6,
                            translateY: [0, 50],
                            shouldAlwaysCompleteAnimation: true,
                            expanded: false,
                        },
                        {
                            image: bg5,
                            translateY: [0, 60],
                            shouldAlwaysCompleteAnimation: true,
                            expanded: false,
                        },
                        {
                            image: bg4,
                            translateY: [0, 70],
                            shouldAlwaysCompleteAnimation: true,
                            expanded: false,
                        },
                        {
                            image: bg3,
                            translateY: [0, 80],
                            shouldAlwaysCompleteAnimation: true,
                            expanded: false,
                        },
                        // {
                        //   image: bg2,
                        //   translateY: [0, 90],

                        //   shouldAlwaysCompleteAnimation: true,
                        //   expanded: false,
                        // },
                        {
                            image: bg0,
                            translateY: [0, 100],
                            className: "animate__animated animate__backInDown",
                            shouldAlwaysCompleteAnimation: true,
                            expanded: false,
                        },
                    ]}
                />

            </ParallaxProvider>
            <img alt="grass overlay" className="grass-overlay" src={require("../images/parallax/parallax_1.png")} />
            <div className="landing-container">
                <div className="full-page top">
                    <h1 className="welcome-text">Welcome Chum!</h1>
                    {/* <div className="welcome-img animate__animated animate__bounce" /> */}
                    <p className="animated fadeInUp">
                        <b>TreesNClouds</b> is the neatest nug delivery service around! With
                        a simple tap and swipe you can get your stuff delivered in a jiffy!
                        It's easy as 1. 2.. 3... 4.... 5.....
                    </p>
                    <div className="scroller">
                        <span className="fa fa-angle-down" />
                    </div>
                </div>

                <div className="full-page how-it-works vertcenter">
                    <div className="container-fluid">
                        <img alt="how text" src={require("../images/full/how.png")} className="hdr animate__animated animate__tada" />
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-12 text-center">
                                <div className="content">
                                    <img
                                        alt="download app"
                                        src={require("../images/full/1.png")}
                                        width="100%"
                                        className="how-img"
                                    />
                                    <h4>
                                        <b>1. Download & Register</b>
                                    </h4>
                                    <p>
                                        Download the application from the IOS or Android app store.
                                        Register for an account and get started.{" "}
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 col-12 text-center">
                                <div className="content">
                                    <img
                                        alt="pick a spot"
                                        src={require("../images/full/3.png")}
                                        width="100%"
                                        className="how-img"
                                    />
                                    <h4>
                                        <b>2. Pick a spot</b>
                                    </h4>
                                    <p>
                                        Pick the location that you want to have your stoffs
                                        delivered. The products will only show you what can be
                                        delivered.{" "}
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-12 col-sm-12 col-12 text-center">
                                <div className="content">
                                    <img
                                        alt="buy buy buy"
                                        src={require("../images/full/4.png")}
                                        width="100%"
                                        className="how-img"
                                    />
                                    <h4>
                                        <b>3. Buy buy buy</b>
                                    </h4>
                                    <p>
                                        Buy your products using a credit card. No more visiting the
                                        ATM or having to dig for change in your couch.{" "}
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-12 col-sm-12 col-12 text-center">
                                <div className="content">
                                    <img
                                        alt="get it delivered"
                                        src={require("../images/full/5.png")}
                                        width="100%"
                                        className="how-img"
                                    />
                                    <h4>
                                        <b>4. Get it delivered!</b>
                                    </h4>
                                    <p>
                                        Your stoffs will be delivered in no time. Sit back, relax
                                        and put on some good tunes. Bout to get high and go low.{" "}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="full-page vertcenter signup">
                    <img
                        alt="sign up"
                        src={require("../images/full/sign-up.png")}
                        width="300"
                        className="animated fadeInDown"
                    />
                    <p className="animated fadeInUp">
                        <b>We'll notify you when our app is live!</b>
                        <br />
                        Pre-register for an account an be one of the first people in your
                        are to join the TNC crew!
                    </p>

                    <div data-vl-widget="popupTrigger" />
                </div>
                <div className="full-page vertcenter">
                    <img alt="coming soon" src={require("../images/full/coming-soon.png")} width="40%" />
                    <ul className="appstore-links animated fadeInUp">
                        <li>
                            <a aria-label="app store" href="https://www.apple.com/app-store/">
                                <img alt="app store" src={require("../images/full/appstore-ios.png")} />
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://play.google.com/store/games?hl=en_US&gl=US"
                                aria-label="google play store"
                            >
                                <img alt="google play store" src={require("../images/full/appstore-android.png")} />
                            </a>
                        </li>
                    </ul>
                    <ul className="social animated fadeInUp">
                        <li>
                            <a
                                aria-label="facebook"
                                href="https://www.facebook.com/" className="fa fa-facebook" />
                        </li>
                        <li>
                            <a aria-label="instagram" href="https://www.instagram.com/" className="fa fa-instagram" />
                        </li>
                        <li>
                            <a aria-label="twitter" href="https://twitter.com/?lang=en" className="fa fa-twitter" />
                        </li>
                    </ul>
                    <p className="animated fadeInUp">
                        Copyright &copy; TreesNClouds 2024. All rights reserved.
                    </p>
                </div>
            </div>
        </>
    );
}

export default Home;