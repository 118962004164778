
import { DatabaseInstance } from "./databaseClass";
import { FirebaseWebInstance } from "./firebaseClass";
import { UserInstance } from "./userClass";

const schema = "verification";

const VERIFICATION_INITIAL = {
    createdAt: null,
    updatedAt: null,
    removedAt: null,
    isActive: true,
    isNewUser: true,
    ageVerified: false,
    emailVerified: false,
    acceptedPrivacy: false,
    acceptedTerms: false,
    phoneVerified: false,
};

const VERIFICATION_RULES = {
    createdAt: `date`,
    updatedAt: `date`,
    removedAt: `date`,
    isActive: `boolean`,
    isNewUser: `boolean`,
    ageVerified: `boolean`,
    emailVerified: `boolean`,
    phoneVerified: `boolean`,
    acceptedPrivacy: `boolean`,
    acceptedTerms: `boolean`,
};

class VerificationClass {
    // constructor() { }

    public getVerification = async () => {
        const userId = await UserInstance.getCurrentUserId();
        const verificationRecord = await DatabaseInstance.getRecord(
            `${schema}/${userId}`,
            (data: VERIFICATION) => this.setVerification(data)
        );
        return verificationRecord;
    };

    public setVerification = async (verification: VERIFICATION) => {
        const verificationRecord = await DatabaseInstance.setRecord(
            schema,
            await UserInstance.getCurrentUserId(),
            verification,
            VERIFICATION_INITIAL,
            VERIFICATION_RULES
        );

        return verificationRecord;
    };

    public checkEmailVerification = async () => {
        return await FirebaseWebInstance.isEmailVerified()
    }
}

const VerificationInstance = new VerificationClass();

export { VerificationInstance };
