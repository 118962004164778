import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styles from './signup.module.scss'
import tokn from '../images/full/website.png'
import { AuthenticationInstance } from '../utils/authenticationClass'
import { DatabaseInstance } from '../utils/databaseClass'
import { FirebaseWebInstance } from '../utils/firebaseClass'
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";


const auth = getAuth();
const googleProvider = new GoogleAuthProvider();

const SignUp = () => {
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [signUpModal, setSignUpModal] = useState<{ open: boolean, message: string }>({
        open: false,
        message: ''
    })
    const [error, setError] = useState<string | null>(null);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const refId = queryParams.get('ref');
    const qrOwnerId = queryParams.get('id');

    useEffect(() => {
        if (refId && qrOwnerId) {
            createQrPingRecord(refId);
        }
        console.log('singup page hit test', refId, qrOwnerId)
    }, [])

    const createQrPingRecord = async (qrCodeId: string) => {
        console.log('creating a ping record')
        if (!qrCodeId) return;
        try {
            const timestamp = new Date().getTime(); // Get current timestamp
            // const pingKey = `${qrCodeId}-${timestamp}`;

            const pingData = {
                qrCodeId,
                qrOwnerId: qrOwnerId,
                createdAt: FirebaseWebInstance.timeStamp,
                updatedAt: FirebaseWebInstance.timeStamp,
            };


            const response = await DatabaseInstance.setRecord(`qrPing/${qrCodeId}/`, timestamp.toString(), pingData);

            return response;
        } catch (error: any) {
            console.error("Error creating QrPing record:", error);
            setError(error.message || 'Error creating QrPing record');
        }
    };

    const createQrRegistrationRecord = async (qrCodeId: string, newlySignedUpUserId: string) => {
        console.log('creating a registration record')
        if (!qrCodeId || !newlySignedUpUserId) {
            console.log('missing params')
            return;
        }
        try {
            // const registrationKey = `${qrCodeId}-${newlySignedUpUserId}`;

            const registrationData = {
                qrCodeId,
                scannedByUserId: newlySignedUpUserId,
                createdAt: FirebaseWebInstance.timeStamp,
                updatedAt: FirebaseWebInstance.timeStamp,
            };

            const timestamp = new Date().getTime(); // Get current timestamp

            const response = await DatabaseInstance.setRecord(`qrRegistration/${qrCodeId}/`, timestamp.toString(), registrationData);

            return response;
        } catch (error: any) {
            console.error("Error creating QrRegistration record:", error);
            setError(error.message || 'Error creating QrRegistration record');
        }
    };





    const emailPasswordHandler = async (): Promise<void> => {
        try {
            const user =
                await AuthenticationInstance.emailPasswordAuthentication({ email, password });

            if (!user) {
                setError('User not created')
                return;
            }

            // console.log('user', user)
            if (user.newUser && user.authenticationRecord?.id) {
                // console.log('new user')
                if (refId && qrOwnerId) {
                    // console.log('new user with params')
                    await createQrRegistrationRecord(refId, user.authenticationRecord.id);

                    setSignUpModal({
                        open: true,
                        message: 'You are signed up! Download the app and sign in.'
                    })
                } else {
                    // console.log('new user but no params')
                    // if new user but no params
                    setSignUpModal({
                        open: true,
                        message: 'You are signed up! Download the app and sign in.'
                    })
                }
            } else {
                // console.log('existing user')
                setSignUpModal({
                    open: true,
                    message: 'You are already signed up! Download the app and sign in.'
                })
            }
        } catch (error: any) {
            console.error('Error creating user:', error)
            setError(error.message || 'Error creating user')
        };
    }

    const googleAuthHandler = async (): Promise<void> => {
        try {
            const result = await AuthenticationInstance.handleGoogleAuthentication();

            if (result && result.newUser && result.authenticationRecord?.id) {
                // console.log('verificationRecord', result.verificationRecord)
                // console.log('authenticationRecord', result.authenticationRecord)
                // console.log('newUser', result.newUser)
                if (refId && qrOwnerId) {
                    await createQrRegistrationRecord(refId, result.authenticationRecord.id);
                    console.log('new user with params')
                    setSignUpModal({
                        open: true,
                        message: 'You are signed up! Download the app and sign in.'
                    })
                } else {
                    // if new user but no params
                    console.log('new user but no params')
                    setSignUpModal({
                        open: true,
                        message: 'You are signed up! Download the app and sign in.'
                    })
                }

            } else {
                // console.log('existing user')
                setSignUpModal({
                    open: true,
                    message: 'You are already signed up! Download the app and sign in.'
                })
            }
        } catch (error: any) {
            console.error('error:', error)
            setError(error.message || 'Error with Google Sign-In')
        }
    };

    const facebookAuthHandler = async (): Promise<void> => {
        try {
            const result = await AuthenticationInstance.handleFacebookAuthentication();

            if (result && result.newUser && result.authenticationRecord?.id) {
                console.log('verificationRecord', result.verificationRecord)
                console.log('authenticationRecord', result.authenticationRecord)
                console.log('newUser', result.newUser)
                if (refId && qrOwnerId) {
                    await createQrRegistrationRecord(refId, result.authenticationRecord.id);
                    console.log('new user with params')
                    setSignUpModal({
                        open: true,
                        message: 'You are signed up! Download the app and sign in.'
                    })
                } else {
                    // if new user but no params
                    console.log('new user but no params')
                    setSignUpModal({
                        open: true,
                        message: 'You are signed up! Download the app and sign in.'
                    })
                }

            } else {
                // console.log('existing user')
                setSignUpModal({
                    open: true,
                    message: 'You are already signed up! Download the app and sign in.'
                })
            }
        } catch (error: any) {
            console.error('error:', error)
            setError(error.message || 'Error with Facebook Sign-In')
        }
    }


    const createPingRecord = async (qrCodeId: string, scannedByUserId: string) => {
        if (!qrCodeId || !scannedByUserId) return;
        try {

            const pingKey = `${qrCodeId}-${scannedByUserId}`

            const pingData = {
                qrCodeId,
                scannedByUserId,
                qrOwnerId,
                createdAt: FirebaseWebInstance.timeStamp,
                updatedAt: FirebaseWebInstance.timeStamp,
            };

            const response = await DatabaseInstance.setRecord('ping', pingKey, pingData);

            return response;
        } catch (error: any) {
            console.error("Error creating ping record:", error);
            setError(error.message || 'Error creating ping record')
        }
    };

    const renderAlreadySignedUpModal = () => {
        return (
            <div className={styles.alreadySignedUpModal}>
                <div className={styles.alreadySignedUpModalContent}>
                    <h1>Already signed up</h1>
                    <p>{signUpModal.message}</p>

                    <div className={styles.signupFormInput}>
                        <button onClick={(e) => {
                            setSignUpModal({
                                open: false,
                                message: ''
                            })
                            e.preventDefault()
                        }} type="submit">Download</button>
                    </div>
                </div>
            </div>
        )
    }

    const ErrorModal = ({ message, onClose }: {
        message: string;
        onClose: () => void;
    }) => (
        <div className={styles.errorModal}>
            <div className={styles.errorModalContent}>
                <h1>Error</h1>
                <p>{message}</p>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );




    return (
        <div className={styles.wrapper}>
            {error && <ErrorModal message={error} onClose={() => setError(null)} />}
            {signUpModal.open === true ? renderAlreadySignedUpModal() : (
                <div className={styles.maxWidth}>

                    <div className={styles.signupContainer}>
                        <div className={styles.signupLeft}>
                            <h1>Sign up</h1>
                            <p>Sign up to get access to TreesNclouds</p>
                            <div className={`${styles.imageWrapper} ${styles.desktopOnly}`}>
                                <img className={styles.toknImg} src={tokn} alt="signup" />
                            </div>
                        </div>
                        <div className={styles.signupRight}>
                            <form className={styles.signupForm}>

                                <div className={styles.signupFormInput}>
                                    <label htmlFor="email">Email</label>
                                    <input
                                        onChange={(e) => setEmail(e.target.value)}
                                        type="email" id="email" placeholder="Enter your email" />
                                </div>
                                <div className={styles.signupFormInput}>
                                    <label htmlFor="password">Password</label>
                                    <input
                                        onChange={(e) => setPassword(e.target.value)}
                                        type="password" id="password" placeholder="Enter your password" />
                                </div>
                                <div className={styles.signupFormInput}>
                                    <button onClick={(e) => {
                                        emailPasswordHandler()
                                        e.preventDefault()
                                    }} type="submit">Sign up</button>
                                </div>
                            </form>
                            <div>
                                <p>OR</p>
                            </div>
                            <div>
                                <button onClick={googleAuthHandler}>Sign in with Google</button>
                                <button onClick={facebookAuthHandler}>Sign in with Facebook</button>
                            </div>
                            <div className={`${styles.imageWrapper} ${styles.mobileOnly}`}>
                                <img className={styles.toknImg} src={tokn} alt="signup" />
                            </div>
                        </div>
                    </div>
                </div >
            )}

        </div >
    )
}

export default SignUp