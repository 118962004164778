
import {
    set,
    child,
    ref,
    get,
    getDatabase,
    push,
} from "firebase/database";
import { FirebaseWebInstance } from "./firebaseClass";



class DatabaseClass {

    public refOff = (ref: string) => {
        // return FirebaseInstance.database().ref(ref).off();
    };

    private async validate(data: any, initial: any, rules: any) {

        try {
            // const formatted = await this.format(data, initial);
            // const validation = new Validator(formatted, rules);

            // if (validation.passes()) {
            //   return data;
            // } else {
            //   throw validation.errors;
            // }
            return data;
        } catch (error) {
            throw error;
        }
    }

    public setRecord = async (
        listener: string,
        key?: string,
        data?: any,
        initial?: any,
        rules?: any
    ) => {
        try {
            const validatedData = await this.validate(data, initial, rules);

            set(ref(FirebaseWebInstance.db, `${listener}/${key}`), {
                ...data,
                createdAt: Date.now(),
                updatedAt: Date.now(),
            });

            return {
                ...validatedData,
                id: key,
            };
        } catch (error) {
            throw error;
        }
    };

    public getRecord = async (reducer: string, callback: Function) => {
        const data = await get(child(ref(FirebaseWebInstance.db), reducer));
        if (data.val()) {
            return callback(data.val());
        } else {
            return false;
        }
    };


    public generateKey = (path: string): string | null => {
        const databaseRef = ref(getDatabase(FirebaseWebInstance.app), path);
        const newKeyRef = push(databaseRef);
        return newKeyRef.key;
    };


}

const DatabaseInstance = new DatabaseClass();

export { DatabaseInstance };
