import { BrowserRouter as Router } from "react-router-dom"
import Routing from './routes';


function App() {
  console.log('hello world');
  return (
    <>
      <Router>
        <Routing />
      </Router>
    </>
  );
}

export default App;