class emailPasswordClass {

    public formatUser = (data: EMAIL_PASSWORD_FORMATTED_USER) => {
        const formattedUser: USER = {
            nameFirst: '',
            nameLast: '',
            photo: '',
            email: data.email,
        };
        return formattedUser;
    };
}

const EmailPasswordInstance = new emailPasswordClass();

export { EmailPasswordInstance };
