import { EmailPasswordInstance } from "./emailPasswordClass";
import { FirebaseWebInstance } from "./firebaseClass";
import { DatabaseInstance } from "./databaseClass";
import { UserInstance } from "./userClass";
import { VerificationInstance } from "./verificationClass";
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";
import { GoogleInstance } from "./googleClass";
// const schema = `authentication`;

const AUTHENTICATION_RULES: any = {
    createdAt: `date`,
    updatedAt: `date`,
    removedAt: `date`,
    token: `string`,
    provider: {
        facebook: {
            accessToken: `string`,
            idToken: `string`,
            providerId: `string`,
            signInMethod: `string`,
        },
        google: {
            accessToken: `string`,
            idToken: `string`,
            providerId: `string`,
            signInMethod: `string`,
        },
        password: {
            token: `string`,
            email: `string`,
        },
        phone: {
            accessToken: `string`,
            idToken: `string`,
            providerId: `string`,
            signInMethod: `string`,

        },
    },
};

const AUTHENTICATION_INITIAL: any = {
    createdAt: null,
    updatedAt: null,
    removedAt: null,
    token: ``,
};

const VERIFICATION_INITIAL = {
    createdAt: null,
    updatedAt: null,
    removedAt: null,
    isActive: true,
    isNewUser: true,
    ageVerified: false,
    emailVerified: false,
    acceptedPrivacy: false,
    acceptedTerms: false,
    phoneVerified: false,
};


const USER_INITIAL = {
    photo: `http://static.trees.cloud/toknStoned.png`,
};

class AuthenticationClass {
    private verificationId: string | null = null;


    public emailPasswordAuthentication = async (
        credentials: EMAIL_CREDENTIALS
    ) => {
        // Try authentication with email and password
        try {
            // Verify that the email and password meet certain minimum criteria
            this.verifyEmailPasswordAuthentication(credentials);
            const authentication = await this.handleEmailPasswordAuthentication(
                credentials
            );

            return authentication;
        } catch (error: any) {
            if (error.message === 'user exists') {
                alert('Looks like you already have an account. Download the app and sign in.')
            } else {
                throw new Error(error);
            }
        }
    };

    private verifyEmailPasswordAuthentication = (credentials: {
        email: string;
        password: string;
    }) => {
        const errors: string[] = [];

        // Check if email and password are provided
        if (!credentials.email) {
            errors.push("Email address is required");
        }
        if (!credentials.password) {
            errors.push("Password is required");
        }

        // Strong password validation
        const minLength = 8;
        const hasLowercase = /[a-z]/.test(credentials.password);
        const hasUppercase = /[A-Z]/.test(credentials.password);
        const hasNumber = /[0-9]/.test(credentials.password);
        const hasSpecialChar = /[!@#$%^&*]/.test(credentials.password);

        if (credentials.password.length < minLength) {
            errors.push("Password should be at least 8 characters long");
        }
        if (!hasLowercase) {
            errors.push("Password should contain at least one lowercase letter");
        }
        if (!hasUppercase) {
            errors.push("Password should contain at least one uppercase letter");
        }
        if (!hasNumber) {
            errors.push("Password should contain at least one number");
        }
        if (!hasSpecialChar) {
            errors.push(
                "Password should contain at least one special character (@#$%^&*)"
            );
        }

        if (errors.length > 0) {
            throw new Error(errors.join(", "));
        } else {
            return true;
        }
    };

    //TODO: Email Password
    async handleEmailPasswordAuthentication(
        credentials: EMAIL_CREDENTIALS
    ): Promise<{
        verificationRecord: VERIFICATION;
        authenticationRecord: AUTHENTICATION;
        newUser: boolean;
    }> {
        let authenticationRecord: AUTHENTICATION = AUTHENTICATION_INITIAL;
        let verificationRecord: VERIFICATION = VERIFICATION_INITIAL;
        let userObject: USER = USER_INITIAL;


        try {
            // See if the user can log in with that email address and password
            const firebaseSession = await FirebaseWebInstance.signInWithEmailAndPassword(
                credentials
            );

            // Set the authentication record in the database
            authenticationRecord = await this.setAuthentication(
                {
                    ...AUTHENTICATION_INITIAL,
                    token: firebaseSession._tokenResponse.idToken,
                    provider: {
                        ...AUTHENTICATION_INITIAL.provider,
                        email: {
                            accessToken: firebaseSession._tokenResponse.refreshToken,
                        },
                    },
                },
                firebaseSession.user.uid
            );

            // Format the user object
            userObject = await EmailPasswordInstance.formatUser(
                firebaseSession._tokenResponse
            );

            // See if the user exists in the database
            const userExists = await UserInstance.getUser();

            let newUser = false;
            // If User does not exists
            if (!userExists) {
                newUser = true;
                console.log('creating user!!!!', userObject);
                // Create the User record in the database
                userObject = await UserInstance.setUser(userObject);

                // Create the new verification record in the database
                verificationRecord = await VerificationInstance.setVerification({
                    createdAt: Date.now(),
                    isNewUser: true,
                    ageVerified: true,
                    isActive: true,
                    emailVerified: false, // The user must verify their email
                    acceptedTerms: true,
                    acceptedPrivacy: true,
                });
            }

            // Get the new user object from the database
            userObject = await UserInstance.getUserById(
                firebaseSession.user.uid,
                UserInstance.formatUser
            );

            // Return the verification object to see if the email is verified
            verificationRecord = await VerificationInstance.getVerification();

            // Return the verification and authentication data
            return { verificationRecord, authenticationRecord, newUser };
        } catch {
            throw new Error("Error authenticating with email and password");
        }
    }

    public handleGoogleAuthentication = async (): Promise<{
        verificationRecord: VERIFICATION;
        authenticationRecord: AUTHENTICATION;
        newUser: boolean;
    } | undefined> => {
        let authenticationRecord: AUTHENTICATION = AUTHENTICATION_INITIAL;
        let verificationRecord: VERIFICATION = VERIFICATION_INITIAL;
        let userObject: USER = USER_INITIAL;
        try {
            const response = await signInWithPopup(FirebaseWebInstance.auth, FirebaseWebInstance.googleProvider);

            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(response);
            if (!credential) return;

            const token = credential.accessToken;
            // The signed-in user info.
            const user = response.user;

            // Get the Google Firebase Session
            const firebaseSession = await FirebaseWebInstance.signIn(
                credential
            );

            console.log(`firebaseSession is:`, firebaseSession)

            console.log('credential:', credential)

            console.log('token:', token)

            console.log('user:', user)

            authenticationRecord = await this.setAuthentication(
                {
                    ...AUTHENTICATION_INITIAL,
                    token: firebaseSession._tokenResponse.idToken,
                    provider: {
                        ...AUTHENTICATION_INITIAL.provider,
                        google: {
                            accessToken: firebaseSession._tokenResponse.oauthAccessToken,
                            idToken: firebaseSession._tokenResponse.idToken,
                            providerId: firebaseSession._tokenResponse.providerId,
                            signInMethod: firebaseSession._tokenResponse.kind,
                        },
                    },
                },
                firebaseSession.user.uid
            );

            userObject = await GoogleInstance.formatUser(
                firebaseSession._tokenResponse
            );

            // Get the Google Firebase User Data from the DB
            const userExists = await UserInstance.getUser();

            let newUser = false;
            // If User does not exists
            if (!userExists) {
                newUser = true;
                // Create the User record in the database
                userObject = await UserInstance.setUser(userObject);

                // Create the Verification record in the database
                verificationRecord = await VerificationInstance.setVerification({
                    createdAt: Date.now(),
                    isNewUser: true,
                    ageVerified: true,
                    isActive: true,
                    emailVerified: firebaseSession._tokenResponse.emailVerified,
                    acceptedTerms: true,
                    acceptedPrivacy: true,
                });
            }

            // Get the new user object from the database
            userObject = await UserInstance.getUserById(
                firebaseSession.user.uid,
                UserInstance.formatUser
            );

            // Return the verification object to see if the email is verified
            verificationRecord = await VerificationInstance.getVerification();

            // Return the verification and authentication data
            return { verificationRecord, authenticationRecord, newUser };
        } catch (error: any) {
            console.error('Error with Google Sign-In:', error)
        };
    };

    public handleFacebookAuthentication = async (): Promise<{
        verificationRecord: VERIFICATION;
        authenticationRecord: AUTHENTICATION;
        newUser: boolean;
    } | undefined> => {
        let authenticationRecord: AUTHENTICATION = AUTHENTICATION_INITIAL;
        let verificationRecord: VERIFICATION = VERIFICATION_INITIAL;
        let userObject: USER = USER_INITIAL;

        try {
            const response = await signInWithPopup(FirebaseWebInstance.auth, FirebaseWebInstance.facebookProvider);

            // This gives you a Facebook Access Token. You can use it to access the Facebook API.
            const credential = FacebookAuthProvider.credentialFromResult(response);
            if (!credential) return;

            const token = credential.accessToken;
            // The signed-in user info.
            const user = response.user;

            // Get the Google Firebase Session
            const firebaseSession = await FirebaseWebInstance.signIn(
                credential
            );

            console.log(`firebaseSession is:`, firebaseSession)

            console.log('credential:', credential)

            console.log('token:', token)

            console.log('user:', user)

            authenticationRecord = await this.setAuthentication(
                {
                    ...AUTHENTICATION_INITIAL,
                    token: firebaseSession._tokenResponse.idToken,
                    provider: {
                        ...AUTHENTICATION_INITIAL.provider,
                        facebook: {
                            accessToken: firebaseSession._tokenResponse.oauthAccessToken,
                            idToken: firebaseSession._tokenResponse.idToken,
                            providerId: firebaseSession._tokenResponse.providerId,
                            signInMethod: firebaseSession._tokenResponse.kind,
                        },
                    },
                },
                firebaseSession.user.uid
            );

            // Get the Google Firebase User Data from the DB
            const userExists = await UserInstance.getUser();

            let newUser = false;
            // If User does not exists
            if (!userExists) {
                newUser = true;
                // Create the User record in the database
                userObject = await UserInstance.setUser(userObject);

                // Create the Verification record in the database
                verificationRecord = await VerificationInstance.setVerification({
                    createdAt: Date.now(),
                    isNewUser: true,
                    ageVerified: true,
                    isActive: true,
                    emailVerified: firebaseSession._tokenResponse.emailVerified,
                    acceptedTerms: true,
                    acceptedPrivacy: true,
                });
            }

            // Get the new user object from the database
            userObject = await UserInstance.getUserById(
                firebaseSession.user.uid,
                UserInstance.formatUser
            );

            // Return the verification object to see if the email is verified
            verificationRecord = await VerificationInstance.getVerification();

            // Return the verification and authentication data
            return { verificationRecord, authenticationRecord, newUser };
        } catch (error: any) {
            console.error('Error with Facebook Sign-In:', error)
        };
    }

    private setAuthentication = async (
        authentication: AUTHENTICATION,
        key: string
    ) => {
        const authRecord = await DatabaseInstance.setRecord(
            `authentication`,
            key,
            authentication,
            AUTHENTICATION_INITIAL,
            AUTHENTICATION_RULES
        );
        return authRecord;
    };

    private setEmailVerified = async (verificationData: VERIFICATION) => {
        // Try to set the email to verified in the database and redux
        try {
            // Set the emailVerified to true
            verificationData = { ...verificationData, emailVerified: true };

            // verificationData includes an id, delete it
            delete verificationData?.id;


            // Update the database with the new verification data
            await VerificationInstance.setVerification(verificationData);

            return;
        } catch {
            throw new Error("Could not verify the email address");
        }
    };

    private sendEmailVerificationLink = async (email: string) => {
        // Send the email to verify the users authentication email
        return await FirebaseWebInstance.generateEmailVerificationLink(
            email,

            // TODO: Make a constant
            `https://treesnclouds.com`
        );
    };

}

const AuthenticationInstance = new AuthenticationClass();

export { AuthenticationInstance };