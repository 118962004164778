import { EnvironmentInstance } from "./environmentClass";

/**
 * GoogleClass is responsible for managing Google related operations, 
 * such as formatting user data from Google and fetching user's location.
 */
class GoogleClass {
    /** Contains environment details for Google */
    private google: object;

    /**
     * Instantiates the GoogleClass.
     * Sets the `google` property using the environment details from EnvironmentInstance.
     */
    constructor() {
        this.google = EnvironmentInstance.environment.google;
    }

    /**
     * Format user data fetched from Google to match the required user data structure in the application.
     * 
     * @param {GOOGLE_FORMATTED_USER} data - The raw user data fetched from Google.
     * @returns {USER} The formatted user data.
     */
    public formatUser = (data: GOOGLE_FORMATTED_USER) => {
        const formattedUser: USER = {
            nameFirst: data.firstName,
            nameLast: data.lastName,
            photo: data.photoUrl,
            email: data.email,
        };
        return formattedUser;
    };
}

/** 
 * An instance of the GoogleClass.
 * This is what should be imported and used in other parts of the application.
 */
const GoogleInstance = new GoogleClass();

export { GoogleInstance };
