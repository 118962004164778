class EnvironmentClass {
    public environment = {
        firebase: {
            apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
            authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
            databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
            projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
            storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_FIREBASE_APP_ID,
            measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
        },
        google: {
            apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
            iosClientId: process.env.REACT_APP_GOOGLE_IOS_CLIENT_ID,
            androidClientId: process.env.REACT_APP_GOOGLE_ANDROID_CLIENT_ID,
            webClientId: process.env.REACT_APP_GOOGLE_WEB_CLIENT_ID
        },
        facebook: {
            appId: process.env.REACT_APP_FACEBOOK_APP_ID,
            permissions: process.env.REACT_APP_FACEBOOK_PERMISSIONS,
            appName: process.env.REACT_APP_FACEBOOK_APP_NAME,
        }
    };
}

const EnvironmentInstance = new EnvironmentClass();

export { EnvironmentInstance };