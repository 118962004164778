import { initializeApp, FirebaseApp } from "firebase/app";
import {
    initializeAuth,
    createUserWithEmailAndPassword,
    sendEmailVerification,
    Auth,
    signInWithEmailAndPassword,
    GoogleAuthProvider,
    getAuth,
    signInWithCredential,
    AuthCredential,
    FacebookAuthProvider
} from "firebase/auth";
import { getDatabase, Database, serverTimestamp } from "firebase/database";
import { getStorage, FirebaseStorage } from "firebase/storage";
import { EnvironmentInstance } from "./environmentClass";

interface EMAIL_CREDENTIALS {
    params?: Record<string, string>;
    email: string;
    password: string;
    attempts?: number;
    status?: string;
    token?: {
        provider: {
            token: string;
        };
    };
    loading?: boolean;
    error?: [];
};

class FirebaseWebClass {
    public app: FirebaseApp;
    public auth: Auth;
    public db: Database;
    public storage: FirebaseStorage;
    public timeStamp: any;
    public googleProvider: GoogleAuthProvider;
    public facebookProvider: FacebookAuthProvider;

    public constructor() {
        this.app = initializeApp(EnvironmentInstance.environment.firebase);
        this.auth = getAuth();
        this.db = getDatabase(this.app);
        this.facebookProvider = new FacebookAuthProvider();
        this.googleProvider = new GoogleAuthProvider();
        this.storage = getStorage(this.app);
        this.timeStamp = serverTimestamp();
    }

    public testEnvVars = () => {
        console.log(EnvironmentInstance.environment);
    }

    public createUserWithEmailAndPassword = (credentials: EMAIL_CREDENTIALS) => {
        const { email, password } = credentials;
        return createUserWithEmailAndPassword(this.auth, email, password);
    };

    public signInWithEmailAndPassword = async (credentials: EMAIL_CREDENTIALS): Promise<any> => {
        const { email, password } = credentials;
        console.log('signInWithEmailAndPassword', credentials, '\n\n');

        try {
            // Attempt to sign in the user
            const userCredential = await signInWithEmailAndPassword(this.auth, email ?? '', password ?? '');
            return userCredential;
        } catch (error) {

            // Attempt to create a new user
            try {
                await this.createUserWithEmailAndPassword(credentials);
                return this.signInWithEmailAndPassword(credentials);
            } catch (createError) {
                console.error('Error creating user:', createError);
                return createError;
            }
        }
    };

    public signIn = async (credentials: AuthCredential) => {
        return await signInWithCredential(this.auth, credentials).catch((error) => {
            //TODO: Delete Me
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;
            if (errorCode === "auth/account-exists-with-different-credential") {
                alert("Email already associated with another account.");
                // Handle account linking here, if using.
            } else {
                console.error(email, credential, error, errorCode, errorMessage);
            }
            return error;
        });
    };

    public sendEmailVerification = () => {
        if (this.auth.currentUser) {
            return sendEmailVerification(this.auth.currentUser);
        }
    };

    public isEmailVerified = () => {
        return this.auth.currentUser?.emailVerified;
    };

    public async generateEmailVerificationLink(userEmail: string, continueUrl: string): Promise<string | null> {
        const actionCodeSettings: any = {
            url: continueUrl, // This should be a URL to your app where you can intercept the email verification link
            handleCodeInApp: true, // This will make sure the link is opened in the same app session
            // Optionally, you can also set iOS and Android settings here if needed
        };

        try {
            await sendEmailVerification(this.auth.currentUser!, actionCodeSettings);
            // Note: This method sends an email verification to the user. However, we're assuming you will intercept it on your app's side using the provided `continueUrl`.

            // If you successfully intercepted the link on your app side, you can return it from here.
            // For now, we just return the continueUrl. In a real-world scenario, you'd want to return the actual verification link.
            return continueUrl;
        } catch (error) {
            console.error("Error generating email verification link:", error);
            return null;
        }
    }

}

const FirebaseWebInstance = new FirebaseWebClass();

export { FirebaseWebInstance };
