import { FirebaseWebInstance } from "./firebaseClass";
import { DatabaseInstance } from "./databaseClass";

const schema = `user`;

const USER_RULES = {
    createdAt: `date`,
    updatedAt: `date`,
    removedAt: `date`,
    id: `string`,
    email: `string`,
    phone: `string`,
    phoneCode: `string`,
    nameFirst: `string`,
    nameLast: `string`,
    username: `string`,
    photo: `string`,
};

const USER_INITIAL = {
    photo: `http://static.trees.cloud/toknStoned.png`,
};

/**
 * UserClass is responsible for handling all user-related functions.
 */
class UserClass {
    /**
     * Retrieves the current user from the authentication module.
     * @returns Firebase User object.
     */
    public getCurrentUserFromAuth = async () => {
        return await FirebaseWebInstance.auth.currentUser;
    };

    /**
     * Retrieves the user details from the database.
     * @returns User data.
     */
    public getUser = async () => {
        const userId = await this.getCurrentUserId();
        const userRecord = await DatabaseInstance.getRecord(
            `${schema}/${userId}`,
            this.setUser
        );

        return userRecord;
    };

    /**
     * Retrieves user details by a specific ID.
     * @param {string} id - The ID of the user.
     * @param {Function} callback - Callback function.
     * @returns User data.
     */
    public getUserById = async (id: string, callback: Function) => {
        const userRecord = await DatabaseInstance.getRecord(
            `${schema}/${id}`,
            callback
        );

        return userRecord;
    };

    /**
     * Retrieves the ID of the current user from the authentication module.
     * @returns User ID.
     */
    public getCurrentUserId = async () => {
        return await FirebaseWebInstance.auth.currentUser?.uid;
    };

    /**
     * Retrieves the token of the current user from the authentication module.
     * @returns User ID token.
     */
    public getCurrentUserIDToken = () => {
        return FirebaseWebInstance.auth.currentUser?.getIdToken(true);
    };

    /**
     * Sets the user details in the database.
     * @param {USER} user - The user data.
     * @returns The set record.
     */
    public setUser = async (user: USER) => {
        console.log('setting user with:', user)
        const userRecord = await DatabaseInstance.setRecord(
            schema,
            await this.getCurrentUserId(),
            user,
            USER_INITIAL,
            USER_RULES
        );

        return userRecord;
    };

    /**
     * Formats user data to conform to a specified structure.
     * @param {any} data - The raw user data.
     * @returns Formatted user data.
     */
    public formatUser = (data: any) => {
        const formattedUser: USER = {
            ...USER_INITIAL,
            ...data,
        };

        return formattedUser;
    };

    /**
     * Validates user profile details.
     * @param {USER} user - The user data to validate.
     */
    public validateProfile = async (user: USER) => {
        const errors: any = [];

        // Validation logic
        // ... [omitted for brevity]

        if (errors.length) {
            throw new Error(errors.join(", "));
        }
    };

    /**
     * Saves the user profile after validation.
     * @param {USER} user - The user data to save.
     */
    public saveProfile = async (user: USER) => {
        try {
            await this.validateProfile(user);
            await this.setUser(user);
        } catch (error) {
            throw error;
        }
    };


    userId: any;

    // public setUserCoordinates = (location:USER_LOCATION) => ({
    //   latitude: location.coords.latitude,
    //   longitude: location.coords.longitude,
    //   latitudeDelta: 0.0005,
    //   longitudeDelta: 0.0005,
    // });
}

const UserInstance = new UserClass();

export { UserInstance };